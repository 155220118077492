import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SmartLink from "../SmartLink/SmartLink"
const GameCard = props => {
  const {
    icon350w,
    title,
    description,
    linkUrl,
    backgroundColor,
    styles,
    isThumbnail,
  } = props

  const image = getImage(icon350w)

  const card = (
    <>
      <div className={styles.logo}>
        <GatsbyImage
          image={image}
          alt={title}
          imgClassName={styles.gameIcon}
          className={styles.gameIconContainer}
          objectFit="contain"
          objectPosition="left"
        />
      </div>
      <div className={styles.descriptionContainer}>
        <div
          className={styles.description}
          style={{ backgroundColor: backgroundColor }}
        >
          <div className={styles.content}>
            <h2>{title}</h2>
            <div className={styles.divider}>
              <div className={styles.line}></div>
            </div>
            <div className={styles.descriptionText}>{description}</div>
            <div className={styles.playGameButton}>
              <SmartLink
                href={linkUrl}
                style={{ backgroundColor: backgroundColor }}
              >{`View Game!`}</SmartLink>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  if (isThumbnail) {
    return <div className={styles.card}>{card}</div>
  } else {
    return (
      <SmartLink href={linkUrl} className={styles.card}>
        {card}
      </SmartLink>
    )
  }
}
export default GameCard

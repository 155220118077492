import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES, BLOCKS } from "@contentful/rich-text-types"
import GameCardsList from "../GameCardsList/GameCardsList"
import RuleCardList from "../RuleCardList/RuleCardList"
import CareerCardList from "../CareerCardList/CareerCardList"
import InlineButton from "../InlineButton/InlineButton"
import * as RichTextRendererStyles from "../RichTextRenderer/RichTextRenderer.module.scss"
import CoreValue from "../CoreValue/CoreValue"
import Jobs from "../Jobs/Jobs"
import SmartLink from "../SmartLink/SmartLink"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const embbededEntryBlockParser = node => {
  if (!node.data.target) return <div>contentful_id is missing</div>

  const target = node.data.target
  switch (target.__typename) {
    case "ContentfulGameCardsList":
      return <GameCardsList cards={target.gameCards} visuals={target.visuals} />

    case "ContentfulInlineButton":
      const text = target.text
      const textColor = target.textColor
      const backgroundColor = target.backgroundColor
      const href = target.href
      const info = { text, textColor, href, backgroundColor }
      return <InlineButton {...info}></InlineButton>

    case "ContentfulRuleCardList":
      return <RuleCardList cards={target.ruleCards} />

    case "ContentfulCoreValue":
      const title = target.title
      const content = target.content.content
      return <CoreValue title={title} content={content} />

    case "ContentfulCareerCardList":
      return <CareerCardList cards={target.careerCards} />

    case "ContentfulJobsList":
      return <Jobs></Jobs>

    default:
      return (
        <div>RichText embbededEntryBlockParser Missing {target.__typename}</div>
      )
  }
}

const embeddedEntryInlineParser = node => {
  if (!node.data.target) return <div>contentful_id is missing</div>

  const target = node.data.target
  switch (target.__typename) {
    case "ContentfulLinkableImage":
      const { altText, image, url } = target
      const img = getImage(image)
      return (
        <SmartLink href={url} target="_blank">
          <GatsbyImage
            image={img}
            alt={altText}
            imgClassName={RichTextRendererStyles.downloadButton}
            className={RichTextRendererStyles.downloadButtonContainer}
          />
        </SmartLink>
      )
    default:
      return (
        <div>
          RichText embeddedEntryInlineParser Missing {target.__typename}
        </div>
      )
  }
}

const inlineRenderrer = node => {
  return (
    <span className={RichTextRendererStyles.linkList}>
      {node.content.map(link => (
        <SmartLink
          key={link.value.substring(0, 8)}
          href={node.data.uri}
          className={RichTextRendererStyles.link}
        >
          {link.value}
        </SmartLink>
      ))}
    </span>
  )
}
const hrRenderrer = node => {
  return (
    <div className={RichTextRendererStyles.seperator}>
      <span className={RichTextRendererStyles.leftHolder}>
        <span className={RichTextRendererStyles.line}></span>
      </span>
      <span className={RichTextRendererStyles.rightHolder}>
        <span className={RichTextRendererStyles.line}></span>
      </span>
    </div>
  )
}
const RichTextRenderer = props => {
  const { content } = props
  const options = {
    renderNode: {
      "embedded-entry-block": embbededEntryBlockParser,
      "embedded-entry-inline": embeddedEntryInlineParser,
      // paragraph: node => <p>Hello</p>,
      [INLINES.HYPERLINK]: inlineRenderrer,
      [BLOCKS.HR]: hrRenderrer,
    },
  }

  return <div>{renderRichText(content, options)}</div>
}
export default RichTextRenderer

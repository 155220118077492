import React, { useState, useEffect, useCallback } from "react"
import * as JobsStyles from "./Jobs.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import RichTextRenderer from "../RichTextRenderer/RichTextRenderer"
import { LocationMarkerIcon, FilterIcon } from "@heroicons/react/solid"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Jobs = () => {
  const data = useStaticQuery(graphql`
    query JobsQuery {
      allContentfulJob(
        sort: { fields: country___flagIcon___title, order: DESC }
      ) {
        nodes {
          department
          description {
            raw
          }
          job_id
          country {
            country
            flagIcon {
              title
              gatsbyImageData(width: 50, height: 50, placeholder: BLURRED)
            }
          }
          location
          title
          link
        }
      }
    }
  `)

  const [filter, setFilter] = useState(null)

  const {
    allContentfulJob: { nodes: jobs },
  } = data

  let jobsData = []

  jobs.map(job => {
    if (jobsData[job.country.country] instanceof Array === false) {
      jobsData[job.country.country] = []
    }
    jobsData[job.country.country].push(job)
  })

  const [jobsFiltered, setJobsFiltered] = useState(jobs)

  useEffect(() => {
    if (filter) {
      setJobsFiltered(jobsData[filter])
    } else {
      setJobsFiltered(jobs)
    }
  }, [filter])

  return (
    <div className={JobsStyles.jobs}>
      <div className={JobsStyles.filters}>
        <div
          className={`${JobsStyles.filter} ${
            filter === null ? JobsStyles.active : null
          }`}
          onClick={() => setFilter(null)}
        >
          All ({jobs.length})
        </div>
        {Object.keys(jobsData).map(country => (
          <div
            className={`${JobsStyles.filter} ${
              filter === country ? JobsStyles.active : null
            }`}
            onClick={() => setFilter(country)}
          >
            {country} ({jobsData[country].length})
          </div>
        ))}
      </div>
      <div className={JobsStyles.list}>
        <div className={JobsStyles.totalResults}>
          {jobsFiltered.length} Jobs Found
        </div>
        {jobsFiltered.map(job => {
          const flag = getImage(job.country.flagIcon.gatsbyImageData)
          return (
            <div className={JobsStyles.job}>
              <div className={JobsStyles.flag}>
                <a href={job.link} target="_blank">
                  <GatsbyImage
                    layout="fullWidth"
                    image={flag}
                    alt={job.country.country}
                  />
                </a>
              </div>
              <div className={JobsStyles.text}>
                <div className={JobsStyles.title}>{job.title}</div>
                <div className={JobsStyles.details}>
                  <div className={JobsStyles.detailsLeft}>
                    <a href={job.link} className={JobsStyles.companyLink}>
                      @SciPlay{" "}
                      {job.country.country == "United States"
                        ? "USA"
                        : job.country.country}
                    </a>{" "}
                    |{" "}
                    <LocationMarkerIcon
                      width={16}
                      height={16}
                      style={{ display: "inline-block" }}
                    />{" "}
                    {job.location}, {job.country.country}
                  </div>
                  <div className={JobsStyles.button}>
                    <a href={job.link} target="_blank">
                      READ MORE
                    </a>
                  </div>
                </div>
                <div className={JobsStyles.careersDepartment}>
                  {job.job_id && `Job Id: ${job.job_id} |`}
                  <FilterIcon
                    width={16}
                    height={16}
                    style={{ display: "inline-block" }}
                  />
                  {job.department}
                </div>
                <div className={JobsStyles.careersDescription}>
                  <RichTextRenderer content={job.description} />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default Jobs

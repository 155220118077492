import React from "react"
import CareerCard from "../CareerCard/CareerCard"
import Carousel from "../Carousel/Carousel"

const CareerCardList = ({ cards }) => {
  const CareerCards = cards.map(card => {
    const cardInfo = card
    const { contentful_id, title, backgroundColor } = cardInfo
    const info = {
      contentful_id,
      backgroundColor,
      title,
      description: cardInfo.description.description,
      animationThumbnail: cardInfo.animationThumbnail,
    }
    return <CareerCard {...info} />
  })
  return <Carousel items={CareerCards} />
}
export default CareerCardList

// extracted by mini-css-extract-plugin
export var card = "LogoGameCard-module--card--RjzGv";
export var logo = "LogoGameCard-module--logo--5bsap";
export var description = "LogoGameCard-module--description--1BrvY";
export var content = "LogoGameCard-module--content--39m9l";
export var descriptionText = "LogoGameCard-module--descriptionText--2nXoV";
export var playGameButton = "LogoGameCard-module--play-game-button--1Y7cs";
export var gameIcon = "LogoGameCard-module--game-icon--3bdJM";
export var descriptionContainer = "LogoGameCard-module--description-container--1wYf-";
export var divider = "LogoGameCard-module--divider--2KIHA";
export var line = "LogoGameCard-module--line--xT1ce";
// extracted by mini-css-extract-plugin
export var jobs = "Jobs-module--jobs--2Hd5h";
export var filters = "Jobs-module--filters--12_pB";
export var filter = "Jobs-module--filter--nv8Ve";
export var active = "Jobs-module--active--2nE9X";
export var job = "Jobs-module--job--1de3g";
export var flag = "Jobs-module--flag--t_J11";
export var details = "Jobs-module--details--1wrw-";
export var detailsLeft = "Jobs-module--details-left--10y3k";
export var companyLink = "Jobs-module--company-link--3fplj";
export var careersDepartment = "Jobs-module--careers-department--29GL4";
export var text = "Jobs-module--text--1UH5Q";
export var title = "Jobs-module--title--3xPwC";
export var button = "Jobs-module--button--22_Sw";
export var totalResults = "Jobs-module--total-results--2KqR3";
import React from "react"
import * as GameCardsListStyles from "./GameCardsList.module.scss"
import LogoGameCard from "../LogoGameCard/LogoGameCard"
import ThumbnailGameCard from "../ThumbnailGameCard/ThumbnailGameCard"

const GameCardsList = ({ cards, visuals }) => {
  const relevantCards = cards.map(card => {
    const cardInfo = card
    const { title, linkUrl, backgroundColor } = cardInfo
    return {
      title,
      linkUrl,
      backgroundColor,
      description: cardInfo.description.description,
      icon350w:
        visuals === "icon" ? cardInfo.gameIcon350w : cardInfo.gameThumbnail350w,
    }
  })
  return (
    <div className={GameCardsListStyles.gameCardList}>
      {relevantCards.map((card, ind) => {
        return visuals === "icon" ? (
          <LogoGameCard key={`logo-game-card-${ind}`} {...card} />
        ) : (
          <ThumbnailGameCard key={`thumbnail-game-card-${ind}`} {...card} />
        )
      })}
    </div>
  )
}

export default GameCardsList

import React from "react"
import * as CareerCardStyles from "./CareerCard.module.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const CareerCard = ({
  title,
  description,
  animationThumbnail,
  backgroundColor,
}) => {
  const image = getImage(animationThumbnail)
  return (
    <div className={CareerCardStyles.container} style={{ backgroundColor }}>
      <div className={CareerCardStyles.animation}>
        <GatsbyImage
          imgClassName={CareerCardStyles.ruleIcon}
          image={image}
          alt={animationThumbnail.description}
        />
      </div>
      <div className={CareerCardStyles.content}>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  )
}
export default CareerCard
